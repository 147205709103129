<template>
  <form @submit.prevent="save">
    <modal-card :title="'Coupon'">
      <loading v-if="loading" />

      <div v-else>
        <b-field>
          <b-switch
            v-model="isGeneratedCode"
            :disabled="!isNew || processing"
            @input="onManualCodeChange"
            >Auto-generate coupon code</b-switch
          >
        </b-field>

        <b-field v-if="!isNew || !isGeneratedCode" label="Code *">
          <b-input
            v-model="internalCouponId"
            required
            placeholder="Enter coupon code"
            :disabled="!isNew || isGeneratedCode || processing"
          />
        </b-field>

        <b-field>
          <b-switch
            v-model="expires"
            :disabled="processing"
            @input="onExpiresChanged"
            >Auto-expire coupon</b-switch
          >
        </b-field>

        <b-field v-if="expires" label="Expiry date *">
          <schedule-picker
            v-model="form.dateExpires"
            allow-only-date
            :disabled="processing"
          />
        </b-field>

        <b-field label="Use limit *">
          <b-input
            v-model.number="form.useLimit"
            type="number"
            placeholder="Enter use limit"
            :disabled="processing"
            min="1"
          />
        </b-field>

        <b-field v-if="!isNew" label="Use count">
          <b-input
            v-model.number="form.useCount"
            type="number"
            placeholder="Enter use count"
            disabled
            min="0"
          />
        </b-field>
      </div>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!isValid || loading || processing || !hasChange"
        type="submit"
        class="button is-success"
      >
        Save
      </button>
    </modal-card>
  </form>
</template>

<script>
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  deleteField,
  setDoc,
  updateDoc
} from "@firebase/firestore";
export default {
  name: "CouponModal",
  props: {
    couponId: {
      type: String,
      required: false,
      default: ""
    },
    promotionId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      isGeneratedCode: true,
      internalCouponId:
        this.couponId || doc(collection(this.$firestore, `coupons`)).id,
      loading: false,
      processing: false,
      fields: ["useLimit", "dateExpires", "useLimitPerUser"],
      coupon: {},
      expires: false,
      form: {
        dateCreated: null,
        promotionRef: null,
        //dateExpires: null,
        useCount: 0,
        useLimit: 1
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    isValid() {
      if (this.expires && !this.$_.isDate(this.form.dateExpires)) return false;
      if (this.$_.isEmpty(this.promotionId)) return false;
      if (this.$_.isEmpty(this.internalCouponId)) return false;
      return true;
    },
    isNew() {
      return this.$_.isEmpty(this.couponId);
    },
    hasChange() {
      return !this.$_.isEqual(
        this.$_.pick(this.form, this.fields),
        this.$_.pick(this.coupon, this.fields)
      );
    }
  },
  created() {
    this.getCoupon();
  },
  methods: {
    onManualCodeChange(isGeneratedCode) {
      if (!isGeneratedCode) this.$set(this, "internalCouponId", "");
      else
        this.$set(
          this,
          "internalCouponId",
          doc(collection(this.$firestore, `coupons`)).id
        );
    },
    onExpiresChanged(expires) {
      if (expires) this.$set(this.form, "dateExpires", null);
      else this.$delete(this.form, "dateExpires");
    },
    getCoupon() {
      this.loading = true;
      let ref = doc(this.$firestore, "coupons", this.internalCouponId);
      getDoc(ref)
        .then(coupon => {
          this.loading = false;
          if (coupon.exists()) {
            this.coupon = this.$_.merge({}, { _id: coupon.id }, coupon.data());
            this.form = this.$_.merge({}, { _id: coupon.id }, coupon.data());
            if (coupon.data().dateExpires) {
              this.expires = true;
              this.form.dateExpires = coupon.data().dateExpires.toDate();
              this.coupon.dateExpires = coupon.data().dateExpires.toDate();
            }
          }
        })
        .catch(error => {
          this.loading = false;
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    },
    async save() {
      if (!this.isValid) return;

      this.processing = true;
      let form = this.form;
      const couponsRef = doc(this.$firestore, `coupons`, this.internalCouponId);
      if (this.isNew) {
        const existingCoupon = await getDoc(couponsRef);
        if (existingCoupon.exists()) {
          this.processing = false;
          return this.$toast.open({
            message: `Coupon with code ${this.internalCouponId} exists`,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        }

        form.dateCreated = serverTimestamp();
        form.promotionRef = doc(
          this.$firestore,
          `promotions/${this.promotionId}`
        );
      } else {
        form = this.$_.pick(form, ["useLimit", "dateExpires"]);
      }

      if (!this.expires && !this.isNew) form.dateExpires = deleteField();

      (this.isNew ? setDoc(couponsRef, form) : updateDoc(couponsRef, form))
        .then(() => {
          this.processing = false;
          this.$emit("success");
          this.$toast.open({
            message: `Coupon saved`
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error saving coupon`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
